import React from "react";
import { Container, Typography, Box } from "@mui/material";

const MyMasculinity = () => {
  return (
    <Container>
      <Box mt={5} textAlign="center" sx={{ marginTop: "80px" }}>
        <Typography variant="h4" component="h1" mb={2}>
          My Masculinity
        </Typography>
        <Box sx={{ maxWidth: "600px", textAlign: "left", margin: "auto" }}>
          <Typography variant="body1" paragraph>
            My masculinity is soft and gentle. I breathe slowly and I exhale
            vocally. I love feeling the full expansion of my stomach, taking up
            more and more space, and its retreat, my own personal ocean. I move
            decisively, and I leave space for you to close the gap. Peacocks and
            butterflies are two of my favorite animals. I appreciate beauty; I
            am beautiful.
          </Typography>
          <Typography variant="body1" paragraph>
            My masculinity is heavy and grounded. I am dense. I always hold a
            reservoir of energy. I love to grow my body's capacity to endure and
            to act upon the world. I hold others' stories and dilemmas and
            experiences without making them my own. I witness with presence and
            awareness and curiosity and gratitude. I am neither resistant to nor
            subservient to feedback and observations from others. I am a
            stronghold and a pillar and a deeply rooted tree.
          </Typography>
          <Typography variant="body1" paragraph>
            My masculinity is playful and creative. I view the unfolding of my
            life as artistic expression, such that the stumbles are just as
            meaningful as the successes. I know that conversations are gifts and
            that words are magic spells. I love to laugh and to dance; my hips
            are so happy whenever I remember that they exist. I create as a way
            to love myself and to connect with others. I know that all of life
            can be play. I am a happy creator.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default MyMasculinity;
