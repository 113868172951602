// client/src/components/Hero.tsx
import React from "react";
import { Box, Typography, Container } from "@mui/material";

const Hero = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${require("../images/nature.jpg")})`,
        backgroundSize: "cover",
        backgroundPosition: "left top",
        height: "80vh",
        display: "flex",
        alignItems: "center",
        color: "#fff",
      }}
    >
      <Container className="hero-container">
        <Typography className="hero-text" variant="h1">
          Heal emotional wounds from the masculine
        </Typography>
      </Container>
    </Box>
  );
};

export default Hero;
