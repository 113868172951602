import React from "react";
import { Container, Typography, Box } from "@mui/material";

const MyFirstWitnessing = () => {
  return (
    <Container>
      <Box mt={5} textAlign="center" sx={{ marginTop: "80px" }}>
        <Typography variant="h4" component="h1" mb={2}>
          My First Witnessing
        </Typography>
        <Box sx={{ maxWidth: "600px", textAlign: "left", margin: "auto" }}>
          <Typography variant="body1" paragraph>
            It was towards the end of the retreat, just hours after I'd realized
            that this is my purpose, for now. I asked Lana to hold in her mind
            an experience where a man or men had hurt her, where she held a
            wound.
          </Typography>
          <Typography variant="body1" paragraph>
            "Oh gosh, there's so many," she said.
          </Typography>
          <Typography variant="body1" paragraph>
            The purpose of this container was for me to feel, in my body, the
            certainty that I had felt in my mind that morning about healing
            wounds from the masculine. We were there to help each other, to
            support each other with presence and attention and care.
          </Typography>
          <Typography variant="body1" paragraph>
            Lana and I sat down. We looked in each other's eyes; I saw myself
            reflected in hers. I felt into the infinite channel of love in the
            universe expressed through nature and music and the body and so much
            more, and I spoke. I told Lana words of compassion, words of
            empathy. I told her that her experience doesn't define her, that
            she's so much more powerful than what happened to her, that she's so
            deeply loved by so many. She started to tear up. I placed my hands
            on her upper back and her upper chest, and I held her. I
            straightened my spine when I noticed I was slouching. I held space
            for Lana to feel, express, and release her pain. I felt a radiance
            in my heart and a strong and stable presence throughout my body.
          </Typography>
          <Typography variant="body1" paragraph>
            She thanked me, and I thanked her. From that first witnessing, I
            knew that this was my gift to give, and I'm grateful to do so.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default MyFirstWitnessing;
