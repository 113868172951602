import React from "react";
import { Container, Typography, Box } from "@mui/material";

const SpaceholdingSyllabus = () => {
  return (
    <Container className="spaceholding-syllabus">
      <Box mt={5} textAlign="center" sx={{ marginTop: "80px" }}>
        <Typography variant="h4" component="h1" mb={2}>
          Spaceholding Syllabus
        </Typography>
        <Box sx={{ maxWidth: "600px", textAlign: "left", margin: "auto" }}>
          <Typography variant="body1" paragraph>
            This page contains a list of resources that I have utilized, am
            currently utilizing, and intend to utilize in my spaceholding
            practice. It is a living document and it serves to give you a better
            understanding of the resources, information, and perspectives that
            inform my practice.
          </Typography>
          <Typography>
            Two resources that inform this syllabus are{" "}
            <a
              href="https://relicradiation.substack.com/p/resources-for-developing-emotional"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resources For Developing Emotional Spaceholding Capacity
            </a>{" "}
            by{" "}
            <a
              href="https://www.elenalakebodywork.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Elena Lake
            </a>{" "}
            and a similar document provided to me by{" "}
            <a
              href="https://janemiller.xyz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jane Miller
            </a>
            .
          </Typography>
          {/* Add new sections here */}
          <Typography variant="h5" component="h2" my={2}>
            Ongoing Conversational Practices
          </Typography>
          <Typography variant="body1" paragraph>
            Presence: presence emerges from sincerity and curiosity and letting
            go of presenting myself in a particular way or trying to come across
            as good at space holding.
          </Typography>
          <Typography variant="body1" paragraph>
            Intuition: ask instead of assume the other person's experience,
            follow a thread as deep as it wants to go, trust that I know when to
            listen vs. speak.
          </Typography>
          <Typography variant="body1" paragraph>
            Humility: know that each person is the expert on themself, explore
            root reasons by asking "why?" repeatedly, avoid pattern-matching and
            be with the individual in front of me instead.
          </Typography>
          <Typography variant="body1" paragraph>
            Awareness: understand the other person's internal state and their
            desire in the conversation - listened to / problem solving /
            growing, gently navigate away from forceful energy when it arises,
            notice when I am activated or letting in someone else's energy and
            do self-care as needed.
          </Typography>
          <Typography variant="body1" paragraph>
            Reflection: notice when someone is holding space for me in a way
            that feels good, what makes it good?, notice when this happens in
            interviews and podcasts.
          </Typography>
          <Typography variant="h5" component="h2" my={2}>
            Conversations with Spaceholders
          </Typography>
          <Typography variant="body1" paragraph>
            Elena Lake
          </Typography>
          <Typography variant="body1" paragraph>
            Jane Miller
          </Typography>
          <Typography variant="body1" paragraph>
            Rosa Lewis
          </Typography>
          <Typography variant="body1" paragraph>
            Bree Greenberg
          </Typography>
          <Typography variant="body1" paragraph>
            Sam Hinds
          </Typography>
          <Typography variant="body1" paragraph>
            Cory Hess
          </Typography>
          <Typography variant="body1" paragraph>
            Tasshin Fogleman
          </Typography>
          <Typography variant="body1" paragraph>
            Ben Mathes
          </Typography>
          <Typography variant="body1" paragraph>
            @Temenosis
          </Typography>
          <Typography variant="body1" paragraph>
            @ehipassiko444
          </Typography>
          <Typography variant="h5" component="h2" my={2}>
            Workshops and Courses
          </Typography>
          <Typography variant="body1" paragraph>
            Art of Accomplishment Connection Course
          </Typography>
          <Typography variant="body1" paragraph>
            Bioemotive Framework 101 Course
          </Typography>
          <Typography variant="body1" paragraph>
            Empathy Academy Workshops
          </Typography>
          <Typography variant="h5" component="h2" my={2}>
            In-person Events
          </Typography>
          <Typography variant="body1" paragraph>
            Circling/Relating
          </Typography>
          <Typography variant="body1" paragraph>
            Authentic relating
          </Typography>
          <Typography variant="body1" paragraph>
            Family constellation
          </Typography>
          <Typography variant="body1" paragraph>
            Wildman Retreat
          </Typography>
          <Typography variant="body1" paragraph>
            International School of Temple Arts
          </Typography>
          <Typography variant="body1" paragraph>
            Re-evaluation Counseling
          </Typography>
          <Typography variant="h5" component="h2" my={2}>
            Books
          </Typography>
          <Typography variant="body1" paragraph>
            Goddesses in Everywoman
          </Typography>
          <Typography variant="body1" paragraph>
            Gods in Everyman
          </Typography>
          <Typography variant="body1" paragraph>
            God and Sex, Now We Get Both
          </Typography>
          <Typography variant="body1" paragraph>
            Love Yourself Like Your Life Depends On It
          </Typography>
          <Typography variant="body1" paragraph>
            The Four Agreements
          </Typography>

          <Typography variant="body1" paragraph>
            The King Within
          </Typography>
          <Typography variant="body1" paragraph>
            The Language of Emotions
          </Typography>
          <Typography variant="body1" paragraph>
            The Map of Consciousness Explained
          </Typography>
          <Typography variant="body1" paragraph>
            The Nedera Guidebook
          </Typography>
          <Typography variant="h5" component="h2" my={2}>
            Podcasts
          </Typography>
          <Typography variant="body1" paragraph>
            The Art of Accomplishment
          </Typography>
          <Typography variant="body1" paragraph>
            The Healing Place
          </Typography>
          <Typography variant="body1" paragraph>
            Subterranean
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default SpaceholdingSyllabus;
