import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import theme from "../theme";

const Footer = () => {
  return (
    <Box component="footer" py={3} textAlign="center">
      <Container>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          <a
            href="https://twitter.com/strangestloop"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
            }}
          >
            <Box
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                border: "2px solid black",
                p: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TwitterIcon
                sx={{ fontSize: "30px", color: theme.palette.secondary.main }}
              />
            </Box>
          </a>
          <a
            href="https://instagram.com/strangest.loop"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
            }}
          >
            <Box
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                border: "2px solid black",
                p: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme.palette.secondary.main,
              }}
            >
              <InstagramIcon
                sx={{ fontSize: "30px", color: theme.palette.secondary.main }}
              />
            </Box>
          </a>
          <a
            href="https://strangestloop.io"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
            }}
          >
            <Box
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                border: "2px solid black",
                p: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme.palette.secondary.main,
              }}
            >
              <LanguageIcon
                sx={{ fontSize: "30px", color: theme.palette.secondary.main }}
              />
            </Box>
          </a>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
