import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import Hero from "../components/Hero";
import MarsRose from "../images/mars_rose.jpg";
import Mountain from "../images/mountain.jpg";
import Landscape from "../images/landscape.jpg";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <Container>
        <Box mt={4}>
          <Typography className="section-title" variant="h4" component="h3">
            What is this?
          </Typography>
          <Typography variant="body1" paragraph>
            I am holding{" "}
            <strong>
              1-1 conversations for anyone to process their emotional wounds
              that they received from men.
            </strong>{" "}
            The rest of this page has information on why and how I'm doing this,
            who I am, my ideal client, the details of my offerings, and my
            personal curriculum - my qualifications for offering these
            conversations. Thank you for reading.
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography className="section-title" variant="h4" component="h3">
            Grounded Presence for Your Healing
          </Typography>
          <Typography variant="body1" paragraph>
            Actions done by the unaware masculine have caused untold amounts of
            pain, wounding, and suffering. My intention is to hold a container
            where you can fully be with the feelings that arise from your
            emotional wounding from the masculine. By being witnessed, I aim to
            help you achieve an internal shift: relief from looping thoughts
            about something in your past, or a stronger sense of inner peace, or
            greater feelings of self-love.
          </Typography>
          <Typography variant="body1" paragraph>
            I will hold space for your healing. I will witness your rage, your
            despair, your terror, your doubts, your confusion. I will talk with
            you and offer reflections, support, and compassion. I will simply be
            present with you - whatever our container calls for. I trust our
            shared intuition to guide us.
          </Typography>
          <Typography variant="body1" paragraph>
            I am a man who has learned how to integrate, honor, and cherish his
            inner masculine and feminine, and I am here to support you.
          </Typography>
        </Box>
        <Box class="savvycal-box">
          <a
            class="savvycal-button"
            href="https://savvycal.com/strangestloop/witnessing-discovery"
          >
            Free Discovery Call →
          </a>
        </Box>
        <Box mt={4}>
          <img
            src={Mountain}
            alt="mountain"
            style={{
              width: "100%",
              maxWidth: "600px",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <Typography
            mt={4}
            className="section-title"
            variant="h4"
            component="h4"
          >
            Hi, I'm Adam
          </Typography>
          <Typography variant="body1" paragraph>
            For a long time, I viewed femininity and the feminine as this Great
            Mysterious Thing. I got glimpses into the feminine through
            observation and conversation and dating and intimacy, but I decided
            that I could never have a direct understanding of femininity.
          </Typography>
          <Typography variant="body1" paragraph>
            Now, I know that's bullshit.
          </Typography>
          <Typography variant="body1" paragraph>
            I feel my inner feminine when I comfort myself after being rejected.
            I feel my inner feminine when I am sweet and patient with myself. I
            feel my inner feminine when I listen to my intuition.
          </Typography>
          <Typography variant="body1" paragraph>
            I am a man, and I pay attention to wounding caused by men. It's the
            stoic father who keeps an emotional distance, and the coercive
            boyfriend who withholds his attention, and the frightening stranger
            who ignores the verbal and nonverbal no's, and others who are more
            subtle. In the lowest moments of my life, I caused emotional harm to
            women I cared about due to a lack of integrity and a lack of
            attunement to her needs. I'm glad that I've grown far past that
            degree of selfishness and unawareness, and that I've forgiven myself
            for my behavior.
          </Typography>
          <Typography variant="body1" paragraph>
            Now, I'm doing what I can to help heal wounds from men and from the
            masculine. I am experienced with holding space for others thanks to
            40 hours of coaching calls I've had this year. I have secure access
            to my inner masculine and my inner feminine. I have a deep well of
            curiosity that helps me stay present, focused, and attuned to
            conversation partners. And I want to bring all of me to a
            conversation with you.
          </Typography>
          <Typography variant="body1" paragraph>
            If you have wounding from the masculine and you want to have a
            dialogue, or vent, or rage, I am available. I will hold space for
            you and witness you in your full expression, all without needing to
            make it okay. I know that I can't make it okay. But I offer you my
            presence and my compassion, and a desire to see you and understand
            you.
          </Typography>
          <Typography variant="body1" paragraph>
            I invite you to read about{" "}
            <a
              href="/my-first-witnessing"
              style={{ textDecoration: "underline" }}
            >
              my first witnessing
            </a>
            ,{" "}
            <a
              href="/femininity-and-awareness"
              style={{ textDecoration: "underline" }}
            >
              my awareness of femininity
            </a>
            , and{" "}
            <a href="/my-masculinity" style={{ textDecoration: "underline" }}>
              my masculinity
            </a>
            .
          </Typography>
          <Typography variant="body1" paragraph>
            I don't show my face online publicly as this choice helps me feel
            safe and comfortable, but I enjoy being seen privately with clients.
            If you would like to have a free discovery call with me before
            booking a service (required for new clients), you can schedule one{" "}
            <a
              href="https://savvycal.com/strangestloop/witnessing-discovery"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              here
            </a>
            .
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography className="section-title" variant="h4" component="h4">
            Offerings
          </Typography>
          <Typography variant="body1" paragraph>
            My ideal client seeks a shift in their inner state: a reduction in
            looping thoughts about something that happened to them, or a more
            stable feeling of inner peace, or more self-love. They feel that
            they would be better served by speaking with me than with the person
            or people directly involved in their situation.
          </Typography>
          <Typography variant="body1" paragraph>
            My offerings are *not* for you if you seek relief from a physical
            ailment, you suffer from an acute mental illness, or you want a
            spaceholder with a professional licensure. I do not have a medically
            recognized certification for this work, and I do not plan to get one
            at this time. I invite you to see my personal curriculum of
            resources below as well as my broader{" "}
            <a
              href="/spaceholding-syllabus"
              style={{ textDecoration: "underline" }}
            >
              spaceholding syllabus
            </a>
            .
          </Typography>
          <Typography variant="body1" paragraph>
            I require all new clients to book a{" "}
            <a
              href="https://savvycal.com/strangestloop/witnessing-discovery"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              discovery call
            </a>{" "}
            with me first in order for us to learn whether I can be of service
            to them, and whether they would feel safe and comfortable processing
            their emotional wounding with me.
          </Typography>
          <Grid container spacing={6} mt={0} mb={4}>
            <Grid item xs={12} md={12}>
              <Box
                component="a"
                href="https://savvycal.com/strangestloop/virtual-witnessing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card className="virtual-witnessing">
                  <CardContent>
                    <Typography className="offer-title" variant="h5">
                      Virtual Witnessing
                    </Typography>
                    <Typography variant="body1">
                      In this container, I will first reflect the intentions you
                      share. I will hold space for the expression of your
                      wounding and your healing. I will provide messages sourced
                      from my intuition and channeled from the universal supply
                      of love. I may offer practical tools to help move emotions
                      through your body.
                    </Typography>
                    <Typography className="offer-numbers" variant="h6">
                      1 hour | $125
                    </Typography>
                    <Typography className="offer-numbers" variant="h6">
                      Discovery call required for new clients
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                component="a"
                href="https://savvycal.com/strangestloop/virtual-witnessing-pwyw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card className="virtual-witnessing">
                  <CardContent>
                    <Typography className="offer-title" variant="h5">
                      Virtual Witnessing (Pay What You Want)
                    </Typography>
                    <Typography variant="body1">
                      Please select this option if it's appropriate for your
                      financial circumstance. I am happy to hold space for
                      clients of any financial position.
                    </Typography>
                    <Typography className="offer-numbers" variant="h6">
                      1 hour | PWYW
                    </Typography>
                    <Typography className="offer-numbers" variant="h6">
                      Discovery call required for new clients
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                component="a"
                href="https://savvycal.com/strangestloop/physical-witnessing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card className="physical-witnessing">
                  <CardContent>
                    <Typography className="offer-title" variant="h5">
                      Physical Witnessing
                    </Typography>
                    <Typography variant="body1">
                      This container has the same structure and process as
                      Virtual Witnessing, and it takes place in person in
                      Austin, Texas. I will emphasize the healing power of my
                      presence in connection with you. I may offer light,
                      grounded physical touch with my hands if that is in
                      consent.
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      Email me at masculinewitness@gmail.com to confirm time and
                      location before booking this offer.
                    </Typography>
                    <Typography className="offer-numbers" variant="h6">
                      1 hour | $175
                    </Typography>
                    <Typography className="offer-numbers" variant="h6">
                      Discovery call required for new clients
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Typography className="offer-title" variant="h5">
                    Group Witnessing
                  </Typography>
                  <Typography variant="body1">
                    I will join your virtual container of a group of people on a
                    healing journey. In this container, I may witness
                    participants 1-1, or in small groups, or in the full group.
                    I will defer to your understanding of how, logistically, I
                    can best contribute to the group's healing process, or we
                    can collaborate on the logistics.
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    Email me at masculinewitness@gmail.com to discuss details
                    for this offer.
                  </Typography>
                  <Typography className="offer-numbers" variant="h6">
                    Time and price varies
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Box class="savvycal-box">
            <a
              class="savvycal-button"
              href="https://savvycal.com/strangestloop/witnessing-discovery"
            >
              Free Discovery Call →
            </a>
          </Box>
        </Box>
        <Box className="personal-curriculum" mt={4}>
          <Typography className="section-title" variant="h4" component="h4">
            Personal Curriculum
          </Typography>
          <Typography variant="body1">
            <i>Present</i> | Past
          </Typography>
          <br />

          <Typography variant="body1">
            <i>
              <a
                href="https://www.avabear.xyz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bookbear Express by Ava
              </a>
            </i>
          </Typography>
          <Typography variant="body1">
            <i>
              <a
                href="https://read.mindmine.xyz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mind Mine by Isabel
              </a>
            </i>
          </Typography>
          <Typography variant="body1">
            <i>
              <a
                href="https://anchor.fm/loopy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Conversations on womanhood
              </a>
            </i>
          </Typography>
          <Typography variant="body1">
            <i>
              <a
                href="https://strangestloop.io/breakthrough/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Coaching calls
              </a>
            </i>
          </Typography>
          <Typography variant="body1">
            <i>Journaling</i>
          </Typography>
          <Typography variant="body1">
            <i>Self-love meditation</i>
          </Typography>
          <Typography variant="body1">
            <i>Emotional release</i>
          </Typography>
          <Typography variant="body1">
            <a
              href="https://bookshop.org/p/books/goddesses-in-everywoman-powerful-archetypes-in-women-s-lives-jean-shinoda-bolen/6434041"
              target="_blank"
              rel="noopener noreferrer"
            >
              Goddesses in Everywoman by Jean Shinoda Bolen
            </a>
          </Typography>
          <Typography variant="body1">
            <a
              href="https://www.goodreads.com/book/show/179990574-the-eros-sutras-volume-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Eros Sutras, Vol. 1 by Nicole Daedone
            </a>
          </Typography>
          <Typography>
            <a
              href="https://bookshop.org/p/books/love-yourself-like-your-life-depends-on-it-kamal-ravikant/16573874"
              target="_blank"
              rel="noopener noreferrer"
            >
              Love Yourself Like Your Life Depends On It by Kamal Ravikant
            </a>
          </Typography>
          <Typography variant="body1">
            <a
              href="https://bookshop.org/p/books/the-four-agreements-a-practical-guide-to-personal-freedom-don-miguel-ruiz/15278643"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Four Agreements by Don Miguel Ruiz
            </a>
          </Typography>
          <Typography variant="body1">
            <a
              href="https://bookshop.org/p/books/the-map-of-consciousness-explained-a-proven-energy-scale-to-actualize-your-ultimate-potential-david-r-hawkins/14428611"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Map of Consciousness Explained by David R. Hawkins
            </a>
          </Typography>
          <Typography variant="body1">
            <a
              href="https://www.goodreads.com/en/book/show/1958421"
              target="_blank"
              rel="noopener noreferrer"
            >
              The King Within by Robert Moore and Douglas Gillette
            </a>
          </Typography>
          <Typography variant="body1">
            Nervous System Retreats: Two weeks of widening my window of
            tolerance through intentional triggering and practicing techniques
            to return to homeostasis
          </Typography>
          <Box mt={4}>
            <Typography className="section-title" variant="h4" component="h4">
              Ready to work with me?
            </Typography>
            <Typography variant="body1">
              Email me at masculinewitness@gmail.com with any questions. New
              clients must have a{" "}
              <a
                href="https://savvycal.com/strangestloop/witnessing-discovery"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                free discovery call
              </a>{" "}
              with me before booking any services.
            </Typography>
          </Box>
        </Box>

        <Box mt={4} textAlign="center">
          <img
            src={Landscape}
            alt="landscape"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
      </Container>
    </div>
  );
};

export default HomePage;
