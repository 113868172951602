import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import logo from "../logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import theme from "../theme";

const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [navVisible, setNavVisible] = useState(false);
  const [fontSize, setFontSize] = useState("1.25rem"); // Default font size

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const mobileState = windowWidth < 600;
      setIsMobile(mobileState);
      if (!mobileState) {
        setNavVisible(false);
      }
      // Adjust font size when window width is less than 400px
      setFontSize(windowWidth < 400 ? "1rem" : "1.25rem");
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleNav = () => {
    setNavVisible(!navVisible);
  };

  const closeNav = () => {
    setNavVisible(false);
  };

  return (
    <AppBar
      position="absolute"
      color="transparent"
      elevation={0}
      sx={{ height: "64px" }}
    >
      <Toolbar>
        <Button
          component={Link}
          to="/"
          style={{
            height: "64px",
            marginRight: "20px",
            background: "none",
            padding: "0",
          }}
        >
          <img
            src={logo}
            alt="Masculine Witness Logo"
            style={{
              height: "100%",
            }}
          />
        </Button>
        <Typography
          variant="h6"
          style={{ flexGrow: 1, fontSize: fontSize }}
          className="nav-link"
        >
          Masculine Witness
        </Typography>
        {isMobile ? (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              backgroundColor: "black",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "black", // Keeps the background color black on hover
              },
            }}
            onClick={toggleNav}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <>
            <Button
              className="hero-button"
              component={Link}
              to="/"
              onClick={closeNav}
            >
              Home
            </Button>
            <Button
              className="hero-button"
              component={Link}
              to="/writing"
              onClick={closeNav}
            >
              Writing
            </Button>
          </>
        )}
      </Toolbar>
      {navVisible && (
        <nav
          style={{
            position: "absolute",
            top: "80px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "90%",
            background: "white",
            boxShadow: "0 0 0 1px rgba(20,23,48,.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            borderRadius: "5px 5px 6px 6px",
          }}
        >
          <Button
            className="nav-button"
            component={Link}
            to="/"
            style={{ color: theme.palette.secondary.main }}
            onClick={closeNav}
          >
            Home
          </Button>
          <Button
            className="nav-button"
            component={Link}
            to="/writing"
            style={{ color: theme.palette.secondary.main }}
            onClick={closeNav}
          >
            Writing
          </Button>
        </nav>
      )}
    </AppBar>
  );
};

export default Header;
