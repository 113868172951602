import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link

const WritingPage = () => {
  return (
    <Container sx={{ marginTop: "80px" }}>
      <Box mt={5} mb={2} textAlign="center">
        <Typography variant="h4" component="h1" mb={2}>
          Writing
        </Typography>
        <Link
          to="/spaceholding-syllabus"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Box
            mt={4}
            p={3}
            boxShadow={3}
            sx={{ maxWidth: "600px", margin: "auto", marginBottom: "20px" }}
          >
            <Typography
              className="essay-preview-title"
              variant="h5"
              component="h2"
            >
              Spaceholding Syllabus
            </Typography>
            <Typography variant="body1">
              This page contains a list of resources that I have utilized or
              intend to utilize...
            </Typography>
          </Box>
        </Link>
        <Link
          to="/my-first-witnessing"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Box
            mt={4}
            p={3}
            boxShadow={3}
            sx={{ maxWidth: "600px", margin: "auto", marginBottom: "20px" }} // Added marginBottom to create space between boxes
          >
            <Typography
              className="essay-preview-title"
              variant="h5"
              component="h2"
            >
              My First Witnessing
            </Typography>
            <Typography variant="body1">
              It was towards the end of the retreat, just hours after I'd
              realized that this is...
            </Typography>
          </Box>
        </Link>
        <Link
          to="/femininity-and-awareness"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Box
            mt={4}
            p={3}
            boxShadow={3}
            sx={{ maxWidth: "600px", margin: "auto", marginBottom: "20px" }}
          >
            <Typography
              className="essay-preview-title"
              variant="h5"
              component="h2"
            >
              Femininity and Awareness
            </Typography>
            <Typography variant="body1">
              In my life I've been fortunate to gain an increasingly expansive
              understanding of femininity. I don't...
            </Typography>
          </Box>
        </Link>
        <Link
          to="/my-masculinity"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Box
            mt={4}
            p={3}
            boxShadow={3}
            sx={{ maxWidth: "600px", margin: "auto" }}
          >
            <Typography
              className="essay-preview-title"
              variant="h5"
              component="h2"
            >
              My Masculinity
            </Typography>
            <Typography variant="body1">
              My masculinity is soft and gentle. I breathe slowly and I exhale
              vocally. I love feeling...
            </Typography>
          </Box>
        </Link>
      </Box>
    </Container>
  );
};

export default WritingPage;
