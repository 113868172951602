import React from "react";
import { Container, Typography, Box } from "@mui/material";

const FemininityAndAwareness = () => {
  return (
    <Container>
      <Box mt={5} textAlign="center" sx={{ marginTop: "80px" }}>
        <Typography variant="h4" component="h1" mb={2}>
          Femininity and Awareness
        </Typography>
        <Box sx={{ maxWidth: "600px", textAlign: "left", margin: "auto" }}>
          <Typography variant="body1" paragraph>
            In my life I've been fortunate to gain an increasingly expansive
            understanding of femininity. I don't think that this expansion is
            finished; I would not claim that my understanding right now is
            flawless. But I want to share what I see. I want to give you an
            understanding of my awareness.
          </Typography>
          <Typography variant="body1" paragraph>
            In femininity I see a powerful connection to the wisdom of the body.
            I have witnessed sisters who know when to rest, know when to decline
            an invitation, know when to seek comfort and support, all sourced
            from an attunement to their physical bodies. They honor their body's
            wisdom by speaking courageously, by clearly communicating boundaries
            and desires, and by moving towards and away from safe and unsafe
            situations, respectively.
          </Typography>
          <Typography variant="body1" paragraph>
            In femininity I see a powerful capacity to love. I have witnessed
            and received healing, relaxing, nourishing heart energy from
            sisters. This love is generous and caring, and it is discerning. It
            informs the right relationship and the right distance between two
            people. Feminine love is a transmission from the universe's endless
            supply of love. It is a beautiful gift.
          </Typography>
          <Typography variant="body1" paragraph>
            In femininity I see a powerful ability to receive. There is a skill
            is simply being, in relaxing into a safe and protective container,
            and sisters have this skill in abundance. The feminine wants to be
            witnessed - this is vulnerable and brave and a pathway for
            connection. The feminine is charged up by receiving, and she returns
            that energy with presence and love.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default FemininityAndAwareness;
