import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import WritingPage from "./pages/WritingPage";
import MyMasculinity from "./pages/MyMasculinity";
import FemininityAndAwareness from "./pages/FemininityAndAwareness";
import MyFirstWitnessing from "./pages/MyFirstWitnessing";
import SpaceholdingSyllabus from "./pages/SpaceholdingSyllabus";

const App = () => {
  return (
    <div style={{ flex: 1 }}>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/writing" element={<WritingPage />} />
        <Route path="/my-masculinity" element={<MyMasculinity />} />
        <Route
          path="/femininity-and-awareness"
          element={<FemininityAndAwareness />}
        />
        <Route path="/my-first-witnessing" element={<MyFirstWitnessing />} />
        <Route
          path="/spaceholding-syllabus"
          element={<SpaceholdingSyllabus />}
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
